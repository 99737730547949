<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button v-if="disabled && checkIsAdmin()" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="!disabled && checkIsAdmin()" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <h2 class="card-title">User information</h2>
    <div class="card related-user-card">
      <el-form class="related-user-form" ref="related-user-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="Password">
            <el-input :type="'password'" v-model="form.password"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <h2 class="card-title">Base Information</h2>

    <div class="card info-card">
      <el-form ref="staff-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Employee Name" :required="true">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Employee ID">
            <el-input v-model="form.employeeId"></el-input>
          </el-form-item>
          <el-form-item label="Gender" :required="true" style="max-width: 550px;">
            <el-select v-model="form.gender" placeholder="Select the gender">
              <el-option label="Male" value="male"></el-option>
              <el-option label="Female" value="female"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Position">
            <el-input v-model="form.position"></el-input>
          </el-form-item>
          <el-form-item label="Department">
            <el-input v-model="form.department"></el-input>
          </el-form-item>
          <el-form-item label="Phone Number">
            <el-input v-model="form.phoneNumber"></el-input>
          </el-form-item>
          <el-form-item label="HKID/Passport No.">
            <el-input v-model="form.hkID"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Address" style="width: 75%;">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'StaffInfoForm',
  props:{
    viewMode: Boolean,
    eventName: String,
  },
  data(){
    let disabled = false;

    //Default form value
    let form = {
      username: '',
      password: '',
      name: '',
      employeeId: '',
      gender: '',
      position: '',
      department: '',
      phoneNumber: '',
      hkID: '',
      address: '',
    };

    //If view mode is enabled, disable the form
    if(this.viewMode === true){
      disabled = true;
    };

    return {
      disabled,
      form,
    };
  },
  methods:{
    checkIsAdmin(){
      const currentUser = this.currentUser;
      if(currentUser){
        const isAdmin = Common.checkIsAdmin(currentUser);
        return isAdmin;
      }
      return false;
    },
    startEdit(){
      this.disabled = false;
    },
    save(){
      this.$emit(this.eventName, this.form);
      this.disabled = true;
    },
  },
  watch: {
    'currentEmployee': function(){
      this.form = {
        username: this.currentEmployee.username,
        password: this.currentEmployee.password,
        name: this.currentEmployee.employee_name,
        employeeId: this.currentEmployee.employee_id,
        gender: this.currentEmployee.gender,
        position: this.currentEmployee.position,
        department: this.currentEmployee.department ,
        phoneNumber: this.currentEmployee.phone_number,
        hkID: this.currentEmployee.id_card,
        address: this.currentEmployee.address,
      };
    }
  },
  computed: mapState({
    currentUser: state => state.currentUser,
    currentEmployee: state => state.hr.currentEmployee,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.related-user-card{
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .related-user-form{
    margin-left: 15px;
    margin-top: 20px;
  }
}

.info-card{
  padding: 30px;
}

.card-title{
  margin-bottom: 30px;
}

.contact-info{
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;

  .info-card{
    padding-left: 30px;
  }
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
